import liff from '@line/liff';
import { useLocation, useSearchParams } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { LoginLink } from '~/components/parts';
import { useLiffContext } from '~/providers';
import styles from '~/styles/page/openLine/open-line.module.css';

const blockClass = 'open-line';

export const OpenLine: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get('redirectPath');
  const action = searchParams.get('action');
  const { liffId } = useLiffContext();
  const location = useLocation();
  const currentSearch = location.search;
  const lineLink = `https://liff.line.me/${liffId}/callback${currentSearch}`;

  const browserLogin = () => {
    liff.login({ redirectUri: `${window.location.origin}/callback${currentSearch}` });
  };

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>LINEアカウントで新規登録</p>
      <a href={lineLink} className={styles[`${blockClass}__line-button`]} target='_blank' rel='noreferrer'>
        <img src={'/images/icons/ic_line.svg'} className={styles[`${blockClass}__line-button__icon`]} alt='line' />
        <span className={styles[`${blockClass}__line-button__text`]}>LINEアプリで新規登録</span>
      </a>
      <button className={styles[`${blockClass}__button`]} onClick={browserLogin}>
        <img
          src={'/images/icons/ic_smartphone.svg'}
          className={styles[`${blockClass}__button-icon`]}
          alt='smartphone_icon' />

        <span>ブラウザで続行</span>
      </button>
      <p className={styles[`${blockClass}__note`]}>
        ※デフォルトブラウザ以外のブラウザで開いている場合、正常にログインできない場合があります。
      </p>
      <div style={{ textAlign: 'center' }}>
        <LoginLink
          redirectPath={redirectPath || undefined}
          action={action ?? undefined}
          className={styles[`${blockClass}__register-link`]}>

          すでにアカウントお持ちの人はこちら
        </LoginLink>
      </div>
    </div>);

};