import liff from '@line/liff';
import { useLocation, useNavigate } from '@remix-run/react';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useState, type FunctionComponent } from 'react';
import { Loading } from '~/components/parts';
import { useAuth } from '~/hooks';
import { useLoginQueryParameter } from '~/hooks/useLoginQueryParameter';
import { useLiffContext } from '~/providers';

export const AddLineFriend: FunctionComponent = () => {
  const location = useLocation();
  const queryParams = location.search;
  const inflowSourcePath = `/inflow_sources/new${queryParams}`;
  const { loginQueryParameter } = useLoginQueryParameter({ redirectPath: inflowSourcePath });
  const [isWeb, setIsWeb] = useState(false);
  const { fetching, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { liffId } = useLiffContext();
  const qrUrl = `https://liff.line.me/${liffId}${location.pathname}${queryParams}`;

  useEffect(() => {
    if (fetching) return;
    if (isAuthenticated) {
      navigate(inflowSourcePath, { replace: true });
      return;
    }

    const os = liff.getOS();
    if (os === 'web') {
      setIsWeb(true);
      return;
    } else {
      navigate(`/open_line?${loginQueryParameter}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  if (isWeb) {
    return (
      <div style={{ margin: '16px auto 0px', textAlign: 'center' }}>
        <p style={{ marginBottom: 32 }}>スマートフォンでご覧ください</p>
        <div style={{ width: 280, margin: '0 auto' }}>
          <QRCodeCanvas value={qrUrl} size={280} />
        </div>
        <p style={{ marginTop: 32 }}>QRコードを読み取ってください。</p>
        <p style={{ marginTop: 32 }}>
          ※QRコードが読み取れない、QRコードが表示されない場合は、
          <br />
          下記のURLにスマートフォンでアクセスしてください。
        </p>
        <p style={{ wordBreak: 'break-all' }}>{qrUrl}</p>
      </div>
    );
  }

  return <Loading isFull />;
};
