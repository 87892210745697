import { type FunctionComponent } from 'react';
import { Breadcrumb } from '~/components/shared';

export const CompanyIndex: FunctionComponent = () => {
  return (
    <>
      <Breadcrumb text='運営会社' />
      <h1 className='headline1'>運営会社</h1>
      <ul className='c-table c-contents'>
        <li className='c-table__item'>
          <h2 className='c-table__title'>会社名</h2>
          <div className='c-table__body'>
            <p>BUDDICA・DIRECT株式会社</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h2 className='c-table__title'>設立年月</h2>
          <div className='c-table__body'>
            <p>2023年9月</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h2 className='c-table__title'>資本金</h2>
          <div className='c-table__body'>
            <p>9,900万円</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h2 className='c-table__title'>取締役</h2>
          <div className='c-table__body'>
            <p>代表取締役 中野 優作</p>
            <p>取締役 佐川 悠</p>
            <p>取締役 田端 信太郎</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h2 className='c-table__title'>所在地</h2>
          <div className='c-table__body'>
            <p>
              本社
              <br />
              〒150-0002
              <br />
              東京都渋谷区渋谷２丁目14−13
              <br />
              岡崎ビル1008
            </p>
          </div>
        </li>
        <li className='c-table__item'>
          <h2 className='c-table__title'>代表番号</h2>
          <div className='c-table__body'>
            <p>03-6427-9750</p>
          </div>
        </li>
        <li className='c-table__item'>
          <h2 className='c-table__title'>お問い合わせ先</h2>
          <div className='c-table__body'>
            <p>
              info★buddica.direct
              <br />
              ★を@にして送信してください
            </p>
          </div>
        </li>
      </ul>
      <a
        href='https://sapphire-empress-855.notion.site/Buddica-Direct-2e2845f59b494eab887307873163c968'
        target='_blank'
        rel='noopener noreferrer'
        style={{ display: 'block', padding: '0 16px' }}
      >
        <img src={'/images/banners/recruit.png'} alt='recruit' />
      </a>
    </>
  );
};
