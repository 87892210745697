import { type FunctionComponent } from 'react';
import { LoginLink } from '~/components/parts';
import styles from '~/styles/page/favoritesIndex/favorite-empty.module.css';

const blockClass = 'favorite-empty';

type Props = {
  isNotLogin: boolean;
};

export const FavoriteEmpty: FunctionComponent<Props> = ({ isNotLogin }) => {
  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__inner`]}>
        <p className={styles[`${blockClass}__title`]}>お気に入り（0件）</p>
        <p className={styles[`${blockClass}__subtitle`]}>お気に入りしたクルマはありません</p>
        <p className={styles[`${blockClass}__text`]}>お気に入りに追加し、愛車を見つける第一歩を踏み出しませんか？</p>
      </div>
      <img src='/images/pictures/favorite_cars.png' alt='favorite empty' className={styles[`${blockClass}__image`]} />
      {isNotLogin &&
      <div className={styles[`${blockClass}__inner`]}>
          <p className={styles[`${blockClass}__text`]}>
            ログインすることで、あなたのお気に入りしたクルマが確認できます。
          </p>
          <LoginLink className={styles[`${blockClass}__button`]}>
            <img src={'/images/icons/ic_line.svg'} className={styles[`${blockClass}__button-icon`]} alt='line' />
            <span className={styles[`${blockClass}__button-text`]}>LINEでログイン</span>
          </LoginLink>
          <hr className={styles[`${blockClass}__hr`]} />
          <LoginLink className={styles[`${blockClass}__register`]} isNewRegister={true}>
            新規登録
          </LoginLink>
        </div>
      }
    </div>);

};